import { Injectable, WritableSignal, inject, signal } from '@angular/core';

import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';

import { JacidiUser } from '@components/jacidi-components-lib';

interface JacidiProfile extends KeycloakProfile {
  attributes: {
    profilePicture?: string[];
    cartasSiteIds?: string[];
  };
}

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private readonly _keycloakService = inject(KeycloakService);

  public readonly user: WritableSignal<JacidiUser> = signal({
    avatar: 'https://i1.sndcdn.com/artworks-Tur5D7evliAofRTO-J0anHA-t500x500.jpg',
    name: 'Marcos',
    lastname: 'Alberca',
    roles: ['Administrador'],
    username: 'malberca',
  });

  private readonly _siteId = signal<string[]>([]);

  public setUser() {
    if (this._keycloakService.isLoggedIn()) {
      this._keycloakService.loadUserProfile().then(
        (profile) => this.user.set(this._map(profile as JacidiProfile)),
        (err) => console.error(err),
      );
    }
  }

  private _map(profile: JacidiProfile): JacidiUser {
    this._siteId.set(this._getSiteId(profile));
    return {
      name: profile.firstName ?? '',
      lastname: profile.lastName ?? '',
      roles: this._keycloakService.getUserRoles(),
      username: profile.username ?? '',
      avatar: this._getProfilePicture(profile),
    };
  }

  private _getProfilePicture(profile: JacidiProfile): string {
    const defaultAvatar = 'https://i1.sndcdn.com/artworks-Tur5D7evliAofRTO-J0anHA-t500x500.jpg';
    const profilePictureAttribute = profile.attributes?.['profilePicture'];
    const hasProfilePicture = profilePictureAttribute && (profilePictureAttribute as string[]).length > 0;

    return hasProfilePicture ? (profilePictureAttribute as string[])[0] : defaultAvatar;
  }

  private _getSiteId(profile: JacidiProfile): string[] {
    const siteIdAttribute = profile.attributes?.['cartasSiteIds'];
    return siteIdAttribute ? (siteIdAttribute as string[]) : [];
  }

  public get siteIdSignal() {
    return this._siteId.asReadonly();
  }

  public logout() {
    this._keycloakService.logout();
  }
}
